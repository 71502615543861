import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Img from 'gatsby-image'

// RNM As used in theory page and home page
const FeatureGrid = ({ gridItems, columnCount }) => {
  let colClass = "column is-6";
  if (!!columnCount) {
    colClass = "column is-"+columnCount;
  }
  return (
  <div className="columns is-multiline">
    {gridItems.map((item) => (
      <div key={item.text} className={colClass}>
        <section className="section">
          <div className="has-text-centered">
            <div
            >
              {/*console.log(item.image.childImageSharp.fluid.src)*/}
              <PreviewCompatibleImage imageInfo={item} />
            </div>
          </div>
          <p>{item.text}</p>
        </section>
      </div>
    ))}
  </div>
);
}

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default FeatureGrid
